import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AppBarComponent } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import {
  Browser,
  closest,
  extend,
  isNullOrUndefined,
  remove,
  removeClass,
  KeyboardEvents,
} from "@syncfusion/ej2-base";
import { DataManager, Query } from "@syncfusion/ej2-data";
import {
  Day,
  DragAndDrop,
  ExcelExport,
  ICalendarExport,
  ICalendarImport,
  Inject,
  Month,
  Print,
  Resize,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  Timezone,
  ViewDirective,
  ViewsDirective,
  Week,
  WorkWeek,
  Year,
} from "@syncfusion/ej2-react-schedule";
import { tz } from "moment-timezone";
import {
  Calendar,
  ContextMenu,
  DateHeaderTemplate,
  EventTemplate,
  InterstitialPanel,
  QuickInfoTemplate,
  Settings,
  SettingsPanel,
} from './';
import './Scheduler.css';
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import { setSeconds } from '@progress/kendo-react-dateinputs/dist/npm/timepicker/utils';
import { setSecondsToZero } from 'utils';


export const Scheduler = (props) => {

  const {
    actionButtonPanel = null,
    actionButtonHeaderPanel = null,
    customTooltipTemplate = null,
    dataSource = null,
    dateHeaderIcon = null,
    dateHeaderTemplate = null,
    disabled = false,
    editorTemplate = null,
    eventLookupList = "",
    eventTemplate = null,
    eventFields = null,
    eventRendered = null,
    headerComponent = null,
    heightBuffer = 0,
    onCellDoubleClick = null,
    onExport = null,
    onImport = null,
    onPaste = null,
    onPlaylistClick = null,
    onSlotCut = null,
    onSlotDeleted = null,
    onSlotDragStop = null,
    onSlotDuplicated = null,
    onSlotModified = null,
    onSlotResizeStop = null,
    onUndo = null,
    readOnly = false,
    schedule = null,
    loading = false,
    settings = ['FIRST DAY OF WEEK', 'SLOT DURATION', 'SLOT INTERVAL', 'TIME FORMAT'],
    showHeaderBar = true,
    suppressContextMenu = false,
    suppressExporting = false,
    supressImporting = false,
    suppressTooltip = false,
    suppressQuickInfo = false,
    timoutDelay = 500,
  } = props;

  const { Collections } = Calendar;
  const { Timezones, TimeFormats } = Settings;

  let keyBoardAction = null;
  let selectedTarget;
  const currentView = "Week";
  const isTimelineView = false;
  const contextMenuObj = useRef(null);
  const scheduleObj = useRef(null);
  const sidebarRef = useRef(null);
  const interstitialRef = useRef(null);

  const [eventTooltip, setEventTooltip] = useState(true);
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(1);
  const [timeFormat, setTimeFormat] = useState("HH:mm");
  const [timezone, setTimezone] = useState("Etc/GMT");
  const [copiedEventData, setCopiedEventData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const keyConfigs = {
    cut: "ctrl+x",
    copy: "ctrl+c",
    paste: "ctrl+v",
  };

  const getCurrentTimezoneOnInitialLoad = useMemo(() => {
    // This function is gets the current timezone (utc) based on the location and clock setting
    const now = new Date();

    // Get the timezone offset in minutes
    const offsetInMinutes = now.getTimezoneOffset();

    // Convert the offset to hours and minutes string
    const hours = Math.abs(Math.floor(offsetInMinutes / 60));
    const minutes = Math.abs(offsetInMinutes % 60);

    // Format the UTC offset string
    const sign = offsetInMinutes > 0 ? "-" : "+";
    const utcString = `UTC ${sign}${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

    // Find the timezone matching the current UTC
    const currentOffset = Timezones.find(
      (timezone) => timezone.text === utcString
    );

    return currentOffset || null;
  }, [Timezones]);

  const handleContextMenuOpen = (args) => {
    if (suppressContextMenu) {
      args.cancel = true;
      return;
    }

    let newEventElement = document.querySelector(".e-new-event");
    let targetElement = args.event.target;
    selectedTarget = closest(
      targetElement,
      ".e-appointment,.e-work-cells,.e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells"
    );
    if (newEventElement) {
      remove(newEventElement);
      removeClass(
        [document.querySelector(".e-selected-cell")],
        "e-selected-cell"
      );
    }
    scheduleObj.current.closeQuickInfoPopup(); // Automatically closes the Quick Info modal, if currently opened

    if (closest(targetElement, ".e-contextmenu")) {
      return;
    }
    if (isNullOrUndefined(selectedTarget)) {
      args.cancel = true;
      return;
    }
    if (selectedTarget.classList.contains("e-appointment")) {
      let eventObj = scheduleObj.current.getEventDetails(selectedTarget);
      if (eventObj.RecurrenceRule) {
        contextMenuObj.current.showItems(
          ["EditRecurrenceSlot", "DeleteRecurrenceSlot"],
          true
        );
        contextMenuObj.current.hideItems(
          ["Add", "AddRecurrence", "Today", "Save", "Delete"],
          true
        );
      } else {
        // Context menu for event/appointments
        contextMenuObj.current.showItems(
          ["Save", "Delete", "Duplicate", "Cut"],
          true
        );
        contextMenuObj.current.hideItems(
          [
            "Add",
            "AddRecurrence",
            "Today",
            "EditRecurrenceSlot",
            "DeleteRecurrenceSlot",
          ],
          true
        );
      }
      return;
    } else if (
      (selectedTarget.classList.contains("e-work-cells") ||
        selectedTarget.classList.contains("e-all-day-cells")) &&
      !selectedTarget.classList.contains("e-selected-cell")
    ) {
      args.cancel = true; // Disable context menu for creating new event on cell (#Remove when needed)
      removeClass(
        [].slice.call(
          scheduleObj.current.element.querySelectorAll(".e-selected-cell")
        ),
        "e-selected-cell"
      );
      selectedTarget.setAttribute("aria-selected", "true");
      selectedTarget.classList.add("e-selected-cell");
    }
    args.cancel = true; // Disable context menu for creating new event on cell (#Remove when needed)
    contextMenuObj.current.hideItems(
      ["Save", "Delete", "EditRecurrenceSlot", "DeleteRecurrenceSlot"],
      true
    );
    contextMenuObj.current.showItems(["Add", "AddRecurrence", "Today"], true);
  };

  const handleContextMenuSelect = (args) => {
    let selectedMenuItem = args.item.id;
    let eventObj = {};
    if (selectedTarget && selectedTarget.classList.contains("e-appointment")) {
      eventObj = scheduleObj.current.getEventDetails(selectedTarget);
    }
    switch (selectedMenuItem) {
      case "Today":
        scheduleObj.current.selectedDate = new Date();
        break;
      case "Add":
      case "AddRecurrence":
        let selectedCells = scheduleObj.current.getSelectedElements();
        let activeCellsData = scheduleObj.current.getCellDetails(
          selectedCells.length > 0 ? selectedCells : selectedTarget
        );
        if (selectedMenuItem === "Add") {
          scheduleObj.current.openEditor(activeCellsData, "Add");
        } else {
          scheduleObj.current.openEditor(activeCellsData, "Add", false, 1);
        }
        break;
      case "Save":
        /** User edits the slot */
        onSlotModified &&
          onSlotModified({
            ...eventObj,
            State: {
              timeFormat,
              timezone,
            },
          });
        break;
      case "Duplicate":
        /** User edits the slot */
        onSlotDuplicated && onSlotDuplicated({ ...eventObj });
        break;
      case "Cut":
        /** User edits the slot */
        onSlotCut && onSlotCut({ ...eventObj });
        break;
      case "Delete":
        // scheduleObj.current.deleteEvent(eventObj);
        onSlotDeleted &&
          onSlotDeleted({
            ...eventObj,
            State: {
              timeFormat,
              timezone,
            },
          });
        break;
      case "EditOccurrence":
      case "EditSeries":
        if (selectedMenuItem === "EditSeries") {
          let query = new Query().where(
            scheduleObj.current.eventFields.id,
            "equal",
            eventObj.RecurrenceID
          );
          eventObj = new DataManager(
            scheduleObj.current.eventsData
          ).executeLocal(query)[0];
        }
        scheduleObj.current.openEditor(eventObj, selectedMenuItem);
        break;
      case "DeleteOccurrence":
      case "DeleteSeries":
        scheduleObj.current.deleteEvent(eventObj, selectedMenuItem);
        break;
      default:
        scheduleObj.current.selectedDate = new Date();
        break;
    }
  };

  const handleEventData = (events) => {
    if (events && events.length) {
      return events.map((event) => {
        return {
          ...(eventFields && { ...eventFields(event) }),
          CalendarId: 1,
          IsReadonly: false,
          State: {
            timeFormat,
            timezone,
          },
        };
      });
    }
    return [];
  };

  const handleOnCellDoubleClick = (args) => {
    args.cancel = true; // disrupts default behavior of scheduler on double click
    {
      onCellDoubleClick &&
        !readOnly &&
        onCellDoubleClick({ ...args, State: { timeFormat, timezone } });
    }
  };

  const handleOnSlotDragStop = (args) => {
    onSlotDragStop({
      ...args,
      data: {
        ...args.data,
        State: { timeFormat, timezone },
      },
      draggedData: {
        ...scheduleObj.current.eventsData.find((o) => o.Id === args.data.Id),
      },
    });
  };

  const handleOnSlotResizeStop = (args) => {
    onSlotResizeStop({
      ...args,
      data: {
        ...args.data,
        State: { timeFormat, timezone },
      },
      draggedData: {
        ...scheduleObj.current.eventsData.find((o) => o.Id === args.data.Id),
      },
    });
  };

  const handleSchedulerTimeFormat = (args) => {
    setTimeFormat(args.value);
  };

  const onChangeTimeFormat = () => {
    const timeFormatData = TimeFormats.map((value) => {
      return { Name: value.Name, value: value.Value };
    });
    if (timeFormat == timeFormatData[0].value) {
      handleSchedulerTimeFormat(timeFormatData[1]);
    } else {
      handleSchedulerTimeFormat(timeFormatData[0]);
    }
  };

  const generateEvents = () => {
    let eventData = dataSource
      ? handleEventData(dataSource[eventLookupList])
      : [];
    if (Browser.isIE) {
      Timezone.prototype.offset = (date, timezone) =>
        tz.zone(timezone).utcOffset(date.getTime());
    }
    let overviewEvents = extend([], eventData, undefined, true);
    let timezone = new Timezone();
    let currentTimezone = timezone.getLocalTimezoneName();
    
    for (let event of overviewEvents) {
      event.StartTime = timezone.convert(
        event.StartTime,
        "UTC",
        currentTimezone
      );
      event.EndTime = timezone.convert(event.EndTime, "UTC", currentTimezone);
    }
    // return overviewEvents; // overviewEvents converts the time based on your current utc
    for(let event of eventData){
      event.StartTime = setSecondsToZero(event.StartTime);
      event.EndTime = setSecondsToZero(event.EndTime);
    }
    
    return eventData;
  };

  const toggleSidebar = () => {
    sidebarRef.current.toggle();
    if (showBackdrop) {
      setShowBackdrop(true);
    }
  };

  const toggleInterstitialSidebar = () => {
    interstitialRef.current.toggle();
    if (showBackdrop) {
      setShowBackdrop(true);
    }
  };

  const getScheduleStartPeriod = () => {
    if (schedule && schedule.start) {
      return new Date(schedule.start);
    }
    return new Date();
  };

  const getScheduleEndPeriod = () => {
    if (schedule && schedule.end) {
      return new Date(schedule.end);
    }
    return new Date();
  };

  const getNumberOfWeeksFromPeriod = () => {
    if (schedule) {
      // Start date
      var startDate = getScheduleStartPeriod();

      // End date
      var endDate = getScheduleEndPeriod();

      // Calculate the difference in milliseconds between the two dates
      var difference = endDate.getTime() - startDate.getTime();

      // Number of milliseconds in a week
      var millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;

      // Calculate the number of weeks
      var weeks = Math.ceil(difference / millisecondsInWeek);
      return weeks;
    }
    return 1;
  };

  const getNumberOfMonthsFromPeriod = () => {
    if (schedule) {
      // Start date
      var startDate = getScheduleStartPeriod();

      // End date
      var endDate = getScheduleEndPeriod();

      // Calculate the difference in months between the two dates
      var difference = endDate.getMonth() - startDate.getMonth();

      return difference + 1;
    }
    return 1;
  };

  const onActionBegin = (args) => {
    if (
      args.requestType === "eventCreate" ||
      args.requestType === "eventChange"
    ) {
      args.data[0] = {
        ...args.data[0],
        CalendarId: 1,
        IsReadonly: false,
        State: {
          timeFormat,
          timezone,
        },
      };
      let data = args.data instanceof Array ? args.data[0] : args.data;
      args.cancel = !scheduleObj.current.isSlotAvailable(
        data.StartTime,
        data.EndTime
      );
    }
  };

  const keyFunction = (e) => {
    switch (e.action) {
      case "cut":
      case "copy":
        var selectedEvent = document.querySelector(".e-appointment-border");
        const eventData = scheduleObj.current.getEventDetails(selectedEvent); //get selected event details
        setCopiedEventData({ ...eventData, isCut: e.action == "cut" });
        break;
      case "paste":
        var selectedCells = scheduleObj.current.getSelectedElements(); //to get the selected cells
        var cellDetail = scheduleObj.current.getCellDetails(selectedCells);
        const eventDetail = {
          ...copiedEventData,
          StartTime: cellDetail.startTime,
          EndTime: cellDetail.endTime,
        };
        onPaste && onPaste(eventDetail);
        setCopiedEventData({ ...copiedEventData, isCut: false });
        break;
    }
  };

  const _renderQuickInfoContent = (props) => {
    return (
      <div className="quick-info-content">
        {props.elementType === 'cell' ? null : (
          /** Quick Info Custom Template */
          <QuickInfoTemplate {...props} />
        )}
      </div>
    );
  };

  const _renderQuickInfoFooter = (props) => {
    return (
      <div className="quick-info-footer">
        {props.elementType === "cell" ? null : (
          <div className="event-footer">
            <ButtonComponent
              id="more-details"
              cssClass="e-flat"
              content="Edit"
              isPrimary={true}
              {...(onSlotModified && {
                onClick: () =>
                  onSlotModified({
                    ...props,
                    State: {
                      timeFormat,
                      timezone,
                    },
                  }),
              })}
            />
            <ButtonComponent
              id="delete"
              cssClass="e-flat"
              content="Delete"
              {...(onSlotDeleted && {
                onClick: () =>
                  onSlotDeleted({
                    ...props,
                    State: {
                      timeFormat,
                      timezone,
                    },
                  }),
              })}
            />
          </div>
        )}
      </div>
    );
  };

  const _renderHeaderComponent = useCallback(() => {
    return (
      <div className="e-appbar-spacer">
        {/* Tabs between Programmes and Grading goes here */}
        {/* {headerComponent ? headerComponent.renderTabs(activatedTab, setActivatedTab) : null} */}
        {headerComponent}
      </div>
    );
  }, [headerComponent]);

  const _renderDateHeader = useCallback((args) => {
    if (dateHeaderTemplate) {
      return dateHeaderTemplate(args);
    } else {
      return <DateHeaderTemplate args={args} toggleInterstitialSidebar={toggleInterstitialSidebar} onPlaylistClick={onPlaylistClick} icon={dateHeaderIcon} />
    }
  }, [dateHeaderTemplate]);

  const _renderEvent = useCallback((args) => {
    if (eventTemplate) {
      return eventTemplate(args);
    } else {
      return <EventTemplate args={args} />
    }
  }, [eventTemplate]);

  const serviceManager = useMemo(() => {
    const baseServices = [
      Day,
      Week,
      WorkWeek,
      Month,
      Year,
      Print,
      ExcelExport,
      ICalendarImport,
      ICalendarExport,
    ];
    const additionalServices = [];

    if (!readOnly) {
      additionalServices.push(DragAndDrop);
      additionalServices.push(Resize);
    }

    return [...baseServices, ...additionalServices];
  }, [readOnly]);

  const schedulerSettingsProps = {
    eventTooltip,
    scheduleObj,
    sidebarRef,
    showBackdrop,
    suppressTooltip,
    timeFormat,
    handleSchedulerTimeFormat,
    setEventTooltip,
    toggleSidebar,
    settings,
  };

  const interstitialProps = {
    content: () => <div>Content here</div>,
    interstitialRef,
    toggleInterstitialSidebar,
  };

  const contextMenuProps = {
    contextMenuObj,
    handleContextMenuOpen,
    handleContextMenuSelect,
  };

  useEffect(() => {
    setEventTooltip(!suppressTooltip);
  }, [suppressTooltip]);

  useEffect(() => {
    let updatedView = currentView;
    const currentTimezone = getCurrentTimezoneOnInitialLoad.value;
    switch (currentView) {
      case "Day":
      case "TimelineDay":
        updatedView = isTimelineView ? "TimelineDay" : "Day";
        break;
      case "Week":
      case "TimelineWeek":
        updatedView = isTimelineView ? "TimelineWeek" : "Week";
        break;
      case "WorkWeek":
      case "TimelineWorkWeek":
        updatedView = isTimelineView ? "TimelineWorkWeek" : "WorkWeek";
        break;
      case "Month":
      case "TimelineMonth":
        updatedView = isTimelineView ? "TimelineMonth" : "Month";
        break;
      case "Year":
      case "TimelineYear":
        updatedView = isTimelineView ? "TimelineYear" : "Year";
        break;
      case "Agenda":
        updatedView = "Agenda";
        break;
      default:
        updatedView = isTimelineView ? "TimelineWeek" : "Week";
        break;
    }
    scheduleObj.current.currentView = updatedView;
    scheduleObj.current.timezone = currentTimezone; //sets timezone base on location
    setTimezone(currentTimezone);
    // setFirstDayOfWeek(schedule ? getScheduleStartPeriod().getDay() : 0);
    // scheduleObj.current.firstDayOfWeek = getScheduleStartPeriod().getDay();
  }, [isTimelineView, currentView, schedule]);

  useEffect(() => {
    keyBoardAction = new KeyboardEvents(scheduleObj.current.element, {
      keyAction: keyFunction,
      keyConfigs: keyConfigs,
    });
    return () => {
      if (keyBoardAction !== null) keyBoardAction.destroy();
    };
  }, [dataSource, copiedEventData]);

  useEffect(() => { 
    if(!dataSource[eventLookupList] || dataSource[eventLookupList].length === 0) // when null, eventLookupList is not found
        setTimeout(() => setIsLoading(false), 200);
    else{
        if(dataSource[eventLookupList].length > 0) // when not null
            setTimeout(() => setIsLoading(false), timoutDelay);
    }
  }, [dataSource, loading])


  return (
    <>
      <div className="schedule-control-section">
        <div className="content-wrapper">
          {isLoading && (
            <div
              style={{
                width: '100%',
                height: '100%',
                zIndex: 100,
                borderRadius: '6px',
                background: 'var(--op2mise-color-white)',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                <BootstrapSpinner />
              </div>
            </div>)
          } 
          <div className="schedule-overview">
            <AppBarComponent colorMode="Primary">
              {_renderHeaderComponent()}
              {!readOnly && (
                <div className="control-panel calendar-settings">
                  <ButtonComponent
                    id="addBtn"
                    cssClass="overview-toolbar-settings e-inherit"
                    iconCss="e-icons e-plus"
                    iconPosition="Top"
                    content=""
                    disabled={disabled}
                    title="Add"
                    {...(onCellDoubleClick && {
                      onClick: () =>
                        onCellDoubleClick({
                          ...{
                            startTime: null,
                            endTime: null,
                            State: {
                              timeFormat,
                              timezone,
                            },
                          },
                        }),
                    })}
                  />
                </div>
              )}
              {!readOnly && (
                <div className="control-panel calendar-settings">
                  <ButtonComponent
                    id="undoBtn"
                    cssClass="overview-toolbar-settings e-inherit"
                    iconCss="e-icons e-undo"
                    iconPosition="Top"
                    disabled={disabled}
                    content=""
                    {...(onUndo && { onClick: onUndo })}
                    title="Undo"
                  />
                </div>
              )}
              {!supressImporting && (
                <div className="control-panel calendar-settings">
                  <ButtonComponent
                    id="uploadBtn"
                    cssClass="e-inherit"
                    iconCss="e-icons e-upload-1"
                    iconPosition="Top"
                    content=""
                    onClick={onImport}
                    disabled={disabled}
                    title="Import"
                  />
                </div>
              )}
              {!suppressExporting && (
                <div className="control-panel calendar-settings">
                  <ButtonComponent
                    id="exportBtn"
                    cssClass="e-inherit"
                    iconCss="e-icons e-download"
                    iconPosition="Top"
                    content=""
                    onClick={onExport}
                    disabled={disabled}
                    title="Export"
                  />
                </div>
              )}
              <div className="control-panel calendar-settings">
                <ButtonComponent
                  id="changeTimeFormatBtn"
                  cssClass="e-inherit"
                  iconCss="e-icons e-clock"
                  iconPosition="Top"
                  content=""
                  title="Change time format"
                  onClick={onChangeTimeFormat}
                  disabled={disabled}
                />
              </div>
              {actionButtonHeaderPanel && actionButtonHeaderPanel()}
              <div className="control-panel calendar-settings">
                <ButtonComponent
                  id="settingsBtn"
                  cssClass="overview-toolbar-settings e-inherit"
                  iconCss="e-icons e-settings"
                  iconPosition="Top"
                  content=""
                  onClick={toggleSidebar}
                  title="Settings"
                />
              </div>
            </AppBarComponent>
            <div className="overview-content">
              <div className="left-panel">
                <div className="overview-scheduler">
                  <ScheduleComponent
                    id="scheduler"
                    toolbarItems={[{ name: 'Views', align: 'Right', visible: true }]}
                    actionBegin={onActionBegin}
                    cellClick={(args) => (args.cancel = true)} // Disable creating new event on cell (#Remove when needed)
                    cellDoubleClick={handleOnCellDoubleClick}
                    cssClass={getNumberOfWeeksFromPeriod() > 1 ? "sf-theme-op2mise" : "sf-theme-op2mise-2"}
                    currentView={currentView}
                    eventClick={(args) => suppressQuickInfo && (args.cancel = true)}
                    eventDoubleClick={(args) => (args.cancel = true)} // disrupts default behavior of scheduler on double click
                    eventSettings={{ dataSource: generateEvents(), enableTooltip: eventTooltip, tooltipTemplate: customTooltipTemplate }}
                    dateHeaderTemplate={_renderDateHeader}
                    firstDayOfWeek={firstDayOfWeek}
                    group={{ resources: ['Calendars'] }}
                    height={`calc(100vh - ${heightBuffer}px)`}
                    quickInfoTemplates={{
                      content: _renderQuickInfoContent,
                      footer: _renderQuickInfoFooter,
                    }}
                    ref={scheduleObj}
                    selectedDate={getScheduleStartPeriod()}
                    showHeaderBar={showHeaderBar}
                    showTimeIndicator={false}
                    timezone="UTC"
                    timeFormat={timeFormat}
                    width="100%"
                    workHours={{
                      highlight: true,
                      start: '00:00',
                      end: '23:59',
                    }}
                    {...(onSlotDragStop && { dragStop: handleOnSlotDragStop })}
                    {...(onSlotResizeStop && { resizeStop: handleOnSlotResizeStop })}
                    {...(editorTemplate && { editorTemplate })}
                    {...(eventRendered && { eventRendered })}
                  >
                    <ResourcesDirective>
                      <ResourceDirective
                        field="CalendarId"
                        title="Calendars"
                        name="Calendars"
                        dataSource={Collections}
                        query={new Query().where('CalendarId', 'equal', 1)}
                        textField="CalendarText"
                        idField="CalendarId"
                        colorField="CalendarColor"
                      />
                    </ResourcesDirective>
                    <ViewsDirective>
                      <ViewDirective
                        option="Week"
                        interval={getNumberOfWeeksFromPeriod()}
                        displayName="Week"
                        eventTemplate={_renderEvent}
                        // enableLazyLoading={true}
                      />
                      <ViewDirective option="Month" interval={getNumberOfMonthsFromPeriod()} />
                      <ViewDirective option="Year" />
                    </ViewsDirective>
                    <Inject services={serviceManager} />
                  </ScheduleComponent>
                  <ContextMenu {...contextMenuProps} />
                </div>
              </div>
              <SettingsPanel {...schedulerSettingsProps} />
              <InterstitialPanel {...interstitialProps} />
            </div>
          </div>
        </div>
      </div >
      {actionButtonPanel && actionButtonPanel()}
    </>
  );
};
