import * as React from 'react';
import { GetChannelLookupAPI } from 'api';
import { NumericTextbox } from 'op2mise-react-widgets';
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import Header from 'shared/components/header/Header';
import Select from 'shared/components/select/Select'
import { getMondayOfTheWeek, addDaysToDate } from 'utils';
import CAGrading from './CAGrading';
import CAProgrammmes from './CAProgrammes';
import './ChannelArchitecture.css';
import useStore from 'store/AccountStore';
import CAMinutage from './CAMinutage';
import { getAllLastAccessed, saveAllLastAccessed } from 'utils';
import { AppModules } from 'enums';

const ChannelArchitecture = () => {
  const { user } = useStore((state) => state);

  const [activatedTab, setActivatedTab] = React.useState('Program');
  const [channelsList, setChannelsList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [numberOfWeeks, setNumberOfWeeks] = React.useState(1);
  const [quickInfoDetails, setQuickInfoDetails] = React.useState({ header: null, content: null });
  const [selectedChannel, setSelectedChannel] = React.useState(0);
  const [channelInfo, setChannelInfo] = React.useState({
    "channelId": 0,
    "channelName": null
  });

  const [startDate, setStartDate] = React.useState(getMondayOfTheWeek(new Date()));
  const [endDate, setEndDate] = React.useState(addDaysToDate(startDate, numberOfWeeks * 7));

  const tabOptions = ['Program', 'Grading', 'Minutage'];
  const getSession = (channels) => {
    const session = getAllLastAccessed();
      const { organization, activeFunction, menuItem, channelId, channelName, tab } = session;
        if (channelId && channelName) {
          if(channels.find(channel => channel.channelId === channelId)?.channelName){
            setSelectedChannel(channelId);
            setChannelInfo({ channelId: channelId, channelName: channelName })
          }

          if (tab && tabOptions.includes(tab)) {
            setActivatedTab(tab)
          } else {
            saveAllLastAccessed({ tab: tabOptions[0] })
          }
        } else {
          if (tab) saveAllLastAccessed({ tab: activatedTab })
        }
    }

  const getChannelsAPI = () => {
    GetChannelLookupAPI({
      onSuccess: (res) => {
        if (res.length > 0) {
          setChannelsList(res)
          getSession(res);
        }
      },
      // setLoader: setIsLoading,
    });
  };

  const calculateDates = (dayOfTheWeek, weekNumber, startTime, endTime, period) => {
    // Convert period startDate and endDate strings to Date objects
    let startDate = new Date(period.startDate);
    let endDate = new Date(period.endDate);

    // Validate that startDate is before endDate
    if (startDate > endDate) {
      throw new Error('Invalid period: startDate must be before endDate');
    }

    // Calculate the target date based on dayOfTheWeek and weekNumber
    let currentDate = new Date(startDate); // Start from the period startDate
    currentDate.setHours(0, 0, 0, 0); // Start at midnight to avoid time zone issues

    // Find the first occurrence of the dayOfTheWeek within the period
    let currentDay = currentDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    let daysToAdd = dayOfTheWeek - currentDay;
    if (daysToAdd < 0) {
      daysToAdd += 7; // Ensure daysToAdd is positive or zero
    }
    currentDate.setDate(currentDate.getDate() + daysToAdd);

    // Adjust for weekNumber
    if (weekNumber > 1) {
      currentDate.setDate(currentDate.getDate() + (weekNumber - 1) * 7);
    }

    // Format the start date
    let formattedStartDate = `${currentDate.toLocaleString('en-US', { month: 'long' })} ${currentDate.getDate()}, ${currentDate.getFullYear()} ${startTime}`;

    // Adjust end date if endTime is '00:00:00' or overlaps to the next day
    let formattedEndDate = `${currentDate.toLocaleString('en-US', { month: 'long' })} ${currentDate.getDate()}, ${currentDate.getFullYear()} ${endTime}`;
    let startHour = parseInt(startTime.split(':')[0]);
    let endHour = parseInt(endTime.split(':')[0]);

    // Check if endTime is '00:00:00' or overlaps to the next day
    if (endTime === '00:00:00' || (endHour < startHour) || (endHour === startHour && parseInt(endTime.split(':')[1]) < parseInt(startTime.split(':')[1]))) {
      let nextDay = new Date(currentDate);
      nextDay.setDate(nextDay.getDate() + 1); // Move to the next day
      formattedEndDate = `${nextDay.toLocaleString('en-US', { month: 'long' })} ${nextDay.getDate()}, ${nextDay.getFullYear()} ${endTime}`;
    }

    // Return the formatted dates as Date objects
    return {
      startTime: new Date(formattedStartDate),
      endTime: new Date(formattedEndDate)
    };
  };

  const handleTabChange = (tab) => {
    setActivatedTab(tab);
    saveAllLastAccessed({ tab: tab })
  }

  const headerComponent = React.useMemo(() => {
    const activatedTabStyle = { backgroundColor: 'transparent', color: '#FFFFFF', border: 'none', fontWeight: 'bold' };
    const defaultTabStyle = { backgroundColor: 'transparent', color: '#FAFAFA80', border: 'none' };
    const headerTabs = ['Program', 'Grading', 'Minutage'];
    return (
      <div>
        {headerTabs.map((tab, index) => (
          <button
            key={index}
            type='button'
            className='btn'
            style={tab === activatedTab ? activatedTabStyle : defaultTabStyle}
            onClick={() => { handleTabChange(tab) }}
          > {tab}</button>
        ))}
      </div>
    );
  }, [activatedTab]);

  const schedulerProps = React.useMemo(() => {
    return {
      heightBuffer: 177,
      // schedule: { start: startDate, end: endDate },
      showHeaderBar: false,
      quickInfoDetails,
      headerComponent,
      readOnly: true,
    };
  }, [headerComponent]);

  const programmeProps = React.useMemo(() => {
    return {
      schedulerProps,
      numberOfWeeks,
      setNumberOfWeeks,
      startDate,
      endDate,
      channelInfo,
      calculateDates,
    };
  }, [schedulerProps, startDate, endDate, selectedChannel, numberOfWeeks]);

  const gradingProps = React.useMemo(() => {
    return {
      schedulerProps,
      channelInfo,
      calculateDates,
    };
  }, [schedulerProps, channelInfo]);


  const minutageProps = React.useMemo(() => {
    return {
      schedulerProps,
      channelInfo,
      calculateDates,
    };
  }, [schedulerProps, channelInfo]);

  const activeCAComponent = React.useMemo(() => activatedTab === 'Grading'
    ? <CAGrading  {...gradingProps} />
    : activatedTab === 'Minutage' ? <CAMinutage {...minutageProps} />
      : <CAProgrammmes {...programmeProps} />, [activatedTab, endDate, channelInfo, numberOfWeeks]);

  const renderNumericTextbox = React.useCallback(() => {
    return (
      <NumericTextbox
        format='##'
        value={activatedTab === 'Program' ? numberOfWeeks : 1}
        min={1}
        max={52}
        showSpinButton={false}
        onBlur={(args) => { setNumberOfWeeks(parseInt(args.target.value)) }}
        enabled={activatedTab === 'Program' && channelsList.length ? true : false}
        cssClass='e-op2mise-numeric-textbox'
        label='Number of Weeks:'
      />
    );
  }, [activatedTab, numberOfWeeks]);

  const handleSelectChannel = (args) => {
    setSelectedChannel(args.channelId);
    setChannelInfo(args);
    // Save to session
    saveAllLastAccessed({
      channelId: args.channelId,
      channelName: args.channelName,
    })
  }

  React.useEffect(() => {
    setIsLoading(true);
    getChannelsAPI();
  }, []);

  return (
    <div>
      <Header title={'Architecture'} childComponent={(
        <div className='d-flex align-items-center gap-4'>
          {/* Channels Selection/Dropdown */}
          <div className='architecture-banner-channel-selector'>
            <span className='architecture-banner-channel-label'>Channel:</span>
            <div>
              {/** Re-locate this re-usable component to `op2mise-react-widgets */}
              <Select
                text={channelsList.find(channel => channel.channelId === selectedChannel)?.channelName ?? 'Please select a channel'}
                list={channelsList}
                onSelect={(args) => handleSelectChannel(args)}
                width='200px'
              />
            </div>
          </div>

          {/* Number of Weeks Input Field */}
          <div className='architecture-banner-channel-selector'>
            <div>{renderNumericTextbox()}</div>
          </div>
        </div>
      )} />
      <div className='architecture-content'>
        {/* {isLoading ? <div style={{ height: 'calc(100vh - 100px)' }}><BootstrapSpinner /></div> : <Scheduler {...schedulerProps} />} */}
        {/* {isLoading ? <div style={{ height: 'calc(100vh - 100px)' }}><BootstrapSpinner /></div>
          :  */}
        {activeCAComponent}
        {/* } */}
      </div>
    </div>
  );
}

export default ChannelArchitecture;