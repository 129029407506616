import moment from "moment";

export const getEndOfMonth = (date = null) => {
  const targetDate = date ? new Date(date) : new Date();
  const lastDayOfMonth = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth() + 1,
    0
  );
  return lastDayOfMonth;
};

export const adjustDateByTimezoneOffset = (dateString, timezone) => {
  let offset = parseInt(timezone.replace('Etc/GMT', ''), 10) * -1;
  // Convert the date string to a Date object
  let date = new Date(dateString);

  // Add XX hours to the date
  if (offset < 0) {
    date.setHours(date.getHours() - offset);
  } else {
    date.setHours(date.getHours() + offset);
  }

  // Return the updated date
  return date;
};

export const adjustDateIfMidnight = (dateString) => {
  const dateObj = new Date(dateString);

  // Check if the hour is midnight (00:00:00)
  if (dateObj.getUTCHours() === 0) {
    // Add one day
    dateObj.setUTCDate(dateObj.getUTCDate() + 1);
  }

  // Convert the updated date back to the ISO string format
  // const adjustedDateString = dateObj.toISOString();

  return dateObj;
};

export const adjustTimeToMidnight = (dateString, period, timezone) => {
  const date = new Date(dateString);
  // Increment the date by 1 to get the next day
  if (period === 'start') {
    date.setDate(date.getDate());
  } else {
    date.setDate(date.getDate() + 1);
  }
  // Set time to 12:00 AM
  date.setHours(0, 0, 0, 0);
  return adjustDateByTimezoneOffset(date, timezone);
};

export const computeTimeDifference = (start, end) => {
  /**
   * Computes the time difference between two provided dates and returns a string representation of the difference.
   * The function accepts two Date objects (propDate1 and propDate2) and calculates the time elapsed between them.
   * The result is formatted as a descriptive string indicating the difference in years, months, days, hours, minutes, and seconds.
   */

  // Calculate the difference in milliseconds
  let timeDifference = Math.abs(end - start);

  // Convert milliseconds to hours and minutes
  let hours = Math.floor(timeDifference / (1000 * 60 * 60));
  let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  // Format the duration
  let formattedHours = hours.toString().padStart(2, '0');
  let formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

export const formatDateWithMoment = (inputDate, format = 'YYYY-MM-DD') => {
  const formattedDate = moment(inputDate).format(format);
  return formattedDate;
};

export const subtractDaysToDate = (inputDate, daysToAdd) => {
  return moment(inputDate).subtract(daysToAdd, 'days').toDate();
};

export const getMondayOfTheWeek = (inputDate) => {
  const startOfWeek = moment(inputDate).startOf('week').toDate(); // Sunday
  return addDaysToDate(startOfWeek, 1); // Monday
};

export const addDaysToDate = (inputDate, daysToAdd) => {
  return moment(inputDate).add(daysToAdd, 'days').toDate();
};

export const getWeekNumber = (dateString, startDateString, endDateString) => {
  const date = moment(dateString);
  const startDate = moment(startDateString);
  const endDate = moment(endDateString);

  // Validate input dates
  if (!date.isValid() || !startDate.isValid() || !endDate.isValid()) {
    throw new Error('Invalid date format. Please use YYYY-MM-DD format.');
  }

  // Calculate week number
  const weekNumber = date.diff(startDate, 'weeks') + 1;

  // Check if the date is within the range
  if (weekNumber < 1 || date > endDate) {
    return null; // Outside of the specified date range
  }

  return weekNumber;
};

export const setToMidnight = (inputDate) => {
  /**
   * Sets the time of a given Date object to midnight (00:00:00).
   * @param {Date} inputDate - The Date object to modify.
   * @returns {Date} A new Date object with the time set to midnight.
   */
  let date = new Date(inputDate);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const getMonthFromDate = (dateString) => {
  /**
   * Retrieves the abbreviated month name ('JAN' format) from a given date string using Moment.js.
   * @param {string} dateString - The date string to parse. Should be in a format recognized by Moment.js.
   * @returns {string} The abbreviated month name ('JAN' format) of the provided date string.
   *                   Returns 'Invalid Date' if the date string is invalid.
   */
  const date = moment(dateString);

  if (!date.isValid()) {
    return 'Invalid Date';
  }

  return date.format('MMM').toUpperCase();

};

/**
* Sets the seconds of a Date object to 00 and returns the modified Date object.
* 
* @param {Date} dateValue - The original Date object.
* @returns {Date} - A new Date object with seconds set to 00.
*/
export const setSecondsToZero = (dateValue) => {
  if (!(dateValue instanceof Date)) {
    throw new Error("The provided value must be a Date object.");
  }
  // Create a new Date object with seconds set to 00
  const newDate = new Date(dateValue.getTime());
  newDate.setSeconds(0);
  return newDate;
}