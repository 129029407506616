import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import scheduleImportTemplate from 'assets/files/schedule-import-template.xlsx';
import gradeImportTemplate from 'assets/files/channel-architecture-grading-import-template.xlsx';
import minutageImportTemplate from 'assets/files/channel-architecture-minutage-import-template.xlsx';
import programmeImportTemplate from 'assets/files/channel-architecture-programme-import-template.xlsx';
import styles from './ImportSlots.module.css';
import Button from '../../../../../shared/components/button/Button';

export default function ImportFileModalComponent({
  closeModal,
  selectedFile,
  setSelectedFile,
  handleOnImportFile,
  errorMessage,
  sampleTemplate,
}) {
  const chooseFileRef = useRef();

  const handleUploadButton = () => {
    chooseFileRef.current.click();
  };

  const onFileChangeCapture = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const exportSampleTemplate = (template) => {
    switch (template) {
      case 'minutageImportTemplate':
        saveAs(minutageImportTemplate, 'channel-architecture-minutage-import-template.xlsx');
        break;

      case 'programmeImportTemplate':
        saveAs(programmeImportTemplate, 'channel-architecture-programme-import-template.xlsx');
        break;

      case 'gradeImportTemplate':
        saveAs(gradeImportTemplate, 'channel-architecture-grading-import-template.xlsx');
        break;

      default:
        saveAs(scheduleImportTemplate, 'schedule-import-template.xlsx');
        break;
    }
  };

  return (
    <Modal show centered size="lg">
      <div className={styles.body}>
        <div>
          <div style={{ fontWeight: 'bolder' }}>Import</div>
          <p className={styles.smallText}>
            Download a
            <button
              style={{
                color: '#008662',
                cursor: 'pointer',
                border: 'none',
                backgroundColor: 'transparent',
              }}
              type="button"
              onClick={() => exportSampleTemplate(sampleTemplate)}
              onKeyDown={() => exportSampleTemplate(sampleTemplate)}
              role="presentation"
            >
              sample template
              {' '}
            </button>
            to see an example of the required format.
          </p>
          <p
            style={{
              marginTop: '-15px',
              color: 'red',
              fontSize: '14px',
            }}
          >
            {errorMessage}
          </p>

          <div className="d-flex">
            <Button
              text="Choose file"
              onClick={handleUploadButton}
              style={{ marginTop: '29px' }}
            />
            <input
              ref={chooseFileRef}
              hidden
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
              onChange={onFileChangeCapture}
            />

            <p
              className={styles.fileName}
              title={selectedFile && selectedFile.name}
            >
              {selectedFile && selectedFile.name}
            </p>
          </div>

          <div
            style={{
              paddingTop: '10px',
            }}
          >
            <small>Acceptable file formats: Excel, CSV</small>
          </div>
        </div>

        <div className={styles.buttons}>
          <Button defaultBtn text="Cancel" onClick={closeModal} />
          <Button
            text="Import"
            onClick={handleOnImportFile}
            disabled={!selectedFile}
          />
        </div>
      </div>
    </Modal>
  );
}
