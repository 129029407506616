import React from 'react';
import { GetBreakChannelTypeAPI } from 'api';
import { DataGrid } from 'op2mise-react-widgets';
import Button from 'shared/components/button/Button';
import Input from 'shared/components/input/Input';
import Switch from 'shared/components/switch/Switch';
import { uploadFileIcon } from '../../assets/index';
import './BreakPatterns.css';
import ImportFileModalComponent from './modals/ImportFileModalComponent';
import BreakPatternForms from './BreakPatternForms';
import { saveAs } from 'file-saver';
import XLSX from 'sheetjs-style';
import { SaveBreakChannelTemplateAPI } from 'api';

const BreakPatternEditor = ({ mode, onClose, onImport, selectedRow, channelId, setIsDirty }) => {
  const [versionName, setVersionName] = React.useState(selectedRow.version);
  const [status, setStatus] = React.useState(selectedRow.status === 'Active');
  const [versionData, setVersionData] = React.useState([]);
  const { sampleUploadFile, importModalComponents } = BreakPatternForms;
  const [uploadState, setUploadState] = React.useState({
    modalComponent: importModalComponents.import,
    errorMessage: '',
    resultList: [],
    show: false,
  });

  // Input properties
  const props = {
    label: 'Version Name',
    labelStyle: {
      fontWeight: 'bold',
      color: '#6D6D73',
    },
    name: 'versionName',
    type: 'text',
    placeholder: 'Enter version Name',
    value: versionName,
    errorMessage: '',
    maxLength: 99,
    isRequired: true,
    width: '40%',
    onChange: (e) => {
      setVersionName(e.target.value);
    },
    onFocus: () => {},
    onBlur: () => {},
    onClick: () => {},
    disabled: false,
    pattern: '',
    style: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '20px',
    },
    textAlign: 'left',
  };

  // Switch properties
  const switchProps = React.useMemo(
    () => ({
      label: 'Active',
      labelStyle: {
        fontWeight: 'bold',
        color: '#6D6D73',
      },
      text: ['No', 'Yes'],
      active: !status,
      disabled: false,
      onSwitch: (e) => {
        setStatus(!e);
      },
      blurredOnInactive: false,
    }),
    [status, selectedRow.status]
  );

  // Column definitions for the data grid
  const columnDefinitions = React.useMemo(
    () => [
      {
        field: 'type',
        headerName: 'Type',
        headerTooltip: 'Type',
        filterParams: {
          valueFormatter: (params) => {
            return params.value.charAt(0).toUpperCase() + params.value.slice(1);
          },
        },
        valueFormatter: (params) => {
          return params.value.charAt(0).toUpperCase() + params.value.slice(1);
        },
      },
      {
        field: 'slotPosition',
        headerName: 'Slot Position',
        headerTooltip: 'Slot Position',
      },
      {
        field: 'optional',
        headerName: 'Optional',
        headerTooltip: 'Optional',
        cellDataType: 'text',
        filterParams: {
          valueFormatter: (params) => (params.value ? 'Yes' : 'No'),
        },
        valueFormatter: (params) => (params.value ? 'Yes' : 'No'),
      },
      {
        field: 'position',
        headerName: 'Position',
        headerTooltip: 'Position',
        sort: 'asc',
        cellStyle: { textAlign: 'right' },
      },
      {
        field: 'selection',
        headerName: 'Selection',
        headerTooltip: 'Selection',
        filterParams: {
          valueFormatter: (params) => {
            return params.value.charAt(0).toUpperCase() + params.value.slice(1);
          },
        },
        valueFormatter: (params) => {
          return params.value.charAt(0).toUpperCase() + params.value.slice(1);
        },
      },
      {
        field: 'variable',
        headerName: 'Variable',
        headerTooltip: 'Variable',
        cellDataType: 'text',
        filterParams: {
          valueFormatter: (params) => (params.value ? 'Yes' : 'No'),
        },
        valueFormatter: (params) => (params.value ? 'Yes' : 'No'),
      },
      {
        field: 'externalID',
        headerName: 'Asset',
        headerTooltip: 'Asset',
      },
      {
        field: 'eventName',
        headerName: 'Event',
        headerTooltip: 'Event',
      },
      {
        field: 'priority',
        headerName: 'Priority',
        headerTooltip: 'Proirity',
        cellStyle: { textAlign: 'right' },
      },
    ],
    []
  );

  // Returns all fields from columnDefinition except the action column
  const headerFields = columnDefinitions
    .filter((column) => column.field !== 'action')
    .map((column) => column.field);

  const fetchChannelTemplate = (templateId) => {
    GetBreakChannelTypeAPI({
      queryParams: { breakChannelTemplateId: templateId },
      onSuccess: (res) => {
        setVersionData(res);
      },
    });
  };

  const changeImportModal = (attribute, value) => {
    setUploadState((prevState) => ({
      ...prevState,
      [attribute]: value,
    }));
  };

  const handleExportSampleTemplate = () => {
    const excelData = sampleUploadFile;
    const sampleName = `break-patterns-sample-template`;
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileName = `${sampleName}.xlsx`;
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { errors: ws }, SheetNames: ['errors'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName);
  };

  const handeImportFile = () => {
    if (versionData.length > 0) {
      setUploadState({
        ...uploadState,
        modalComponent: importModalComponents.confirm,
      });
    } else {
      confirmImportFile();
    }
  };

  const confirmImportFile = async () => {
    setUploadState({
      ...uploadState,
      modalComponent: importModalComponents.loading,
    });
    var res = await onImport(uploadState.selectedFile);
    if (res.status == 200) {
      setUploadState({
        ...uploadState,
        resultList: res.data.items,
        modalComponent: importModalComponents.success,
      });
      const importedData = res.data.items
        .filter((o) => o.imported)
        .map((value) => {
          return {
            type: value.type,
            slotPosition: value.slotPosition,
            optional: value.optional,
            priority: value.priority,
            selection: value.selection,
            variable: value.variable,
            externalID: value.externalID,
            eventName: value.eventName,
            position: parseInt(value.position),
          };
        });
      setVersionData(importedData);
    }
  };

  const handleUploadModal = () => {
    setUploadState({
      errorMessage: '',
      resultList: [],
      modalComponent: importModalComponents.import,
      show: true,
    });
  };

  const getEditedVersionName = React.useCallback((name) =>{
    if(name !== versionName){
      return versionName
    }
  }, [versionName])

  const saveBreakChannelTemplate = () => {
    let data = {}
    let patterns = []
    if(versionData.length > 0){
      patterns = versionData.map((pattern) => {
        return  {
          "type": pattern.type ?? '',
          "slotPosition": pattern.slotPosition,
          "optional": pattern.optional === 'Yes' ? true : false,
          "priority": !pattern.priority ? 0 : pattern.priority,
          "selection": pattern.selection,
          "isVariableLength": pattern.variable === 'Yes' ? true : false,
          "assetID": 0,
          "eventName": pattern.eventName,
          "position": pattern.position,
        }
      })
    }
    if(mode === 'new'){      
      data = {
        id: null,
        versionName: versionName,
        active: status === 'Active' ? true : false,
        breakChannelTypes: patterns
      }
    } else if(mode === 'edit'){
      if(selectedRow){
        data = {
          id: selectedRow.id,
          versionName: versionName,
          active: status === 'Active' ? true : false,
          breakChannelTypes: patterns
        }
      }
      }
      SaveBreakChannelTemplateAPI({
        queryParams: { breakChannelTemplateId: channelId, payload: data },
        onSuccess: (res) => {
          onClose('view')
          setIsDirty(true)
        },
      });
  }

  React.useEffect(() => {
    if (selectedRow.id) {
      fetchChannelTemplate(selectedRow.id);
    }
  }, [selectedRow]);

  return (
    <div className="break-editor-wrapper">
      <div className="break-editor-header">
        <Input {...props} />
        <Switch {...switchProps} />
        <img
          src={uploadFileIcon}
          alt=""
          title="Import"
          onClick={handleUploadModal}
          style={{
            cursor: 'pointer',
          }}
        />
      </div>
      <DataGrid
        columns={columnDefinitions}
        customVerbiage="No break patterns found"
        enableMultipleRowSelection={false}
        gridHeightBuffer={345}
        gridName="channelSetup-breakPatterns-editor"
        resizable
        rows={versionData}
        sharedColumnProperties={{
          // cellStyle: { fields: headerFields, value: { textAlign: 'left' } },
          flex: { fields: headerFields, value: 1 },
          sortable: { fields: headerFields, value: true },
          filter: { fields: headerFields, value: true },
        }}
        showAlternativeRowHighlight
        suppressRowClickSelection={true}
      />
      <div className="break-editor-footer">
        <Button
          text="Close"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: '#6d6d73',
          }}
          onClick={() => onClose('view')}
        />
        <Button text="Save" onClick={saveBreakChannelTemplate} disabled={!versionName} />
      </div>
      <ImportFileModalComponent
        closeModal={() => {
          setUploadState({ ...uploadState, show: false });
        }}
        selectedFile={uploadState.selectedFile}
        setSelectedFile={(e) => changeImportModal('selectedFile', e)}
        onImportFile={handeImportFile}
        errorMessage={uploadState.errorMessage}
        showModal={uploadState.show}
        onExportSampleTemplate={handleExportSampleTemplate}
        modalComponent={uploadState.modalComponent}
        onProceed={confirmImportFile}
        resultList={uploadState.resultList}
      />
    </div>
  );
};

export default BreakPatternEditor;
