/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable dot-notation */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
import * as React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GetGridStateAPI, SaveGridStateAPI } from 'api';
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import config from './ag-config.properties.json';
import { handleGridStateApplication } from '.';
import './ag-theme-op2mise-2.css';
import './ag-theme-op2mise.css';
import './ag-theme-op2mise-simple.css';

export function DataGridMain(props) {
  const {
    applyCellClassMethod,
    columns,
    columnDefinition,
    defaultColumnProperties,
    disableHighlightOnBookedColumn,
    enableMultipleColumnSorting,
    enableMultipleRowSelection,
    gridHeightBuffer,
    gridIsLoading,
    gridName,
    handleCapitalization,
    handleChildColumnHeaders,
    isMainGridReady,
    loading,
    mainGridRef,
    mainGridRestProps,
    noRowsOverlayComponent,
    onGridReady,
    onRowDataUpdated,
    onSelectionChanged,
    overviewGridRef,
    pageSize,
    pagination,
    rowClassRules,
    rowData,
    setColumnDefinition,
    setGridIsLoading,
    setIsMainGridReady,
    setIsVerticalScroll,
    setRowsToDisplay,
    sharedColumnProperties,
    showAlternativeRowHighlight,
    suppressMovableColumns,
    theme,
    useCheckboxForRowSelection,
  } = props;

  const { grid } = config;

  const [columnIsWithChildren, setColumnIsWithChildren] = React.useState(false);
  const [gridState, setGridState] = React.useState(null);

  const dataGridStyle = {
    height: `calc(100vh - ${gridHeightBuffer}px)`,
    width: '100%',
    boxShadow: '0 10px 20px rgba(0,0,0,.1)',
    position: 'relative',
  };

  const handleBooked = React.useCallback((columnInstance) =>
    /**
     * On initial load, this function maps through the list of columns to identify a column with a field or name resembling 'Booked'.
     * If such column is found, it utilizes the applyCellClassMethod function to apply necessary cell styles for cells within the 'Booked' column.
     * The cell styles basically applies two shades of gray (light and dark) as well as auto text alignment to `right`.
     */
    columnInstance.map((column) => {
      if (column.children && column.children.length) {
        setColumnIsWithChildren(true);
        column.children.map((child) => {
          if (child.field.includes('book') && !disableHighlightOnBookedColumn) {
            child['cellStyle'] = applyCellClassMethod;
          }
          return child;
        });
      }
      return column;
    }), [disableHighlightOnBookedColumn, applyCellClassMethod]);

  const handleSharedColumnProperties = React.useCallback((columnInstance) =>
    /**
     * This function is designed to identify and collect shared properties among columns in an ag-Grid configuration.
     * It iterates through the apply properties across multiple columns.
     * This is useful for optimizing column configurations and ensuring consistency in the application's data presentation.
     */
    columnInstance.map((column) => {
      if (sharedColumnProperties) {
        Object.keys(sharedColumnProperties).forEach((key) => {
          if (column.children && column.children.length) {
            column.children.map((child) => {
              if (sharedColumnProperties[key].fields.includes(child.field) && sharedColumnProperties) {
                child[key] = sharedColumnProperties[key].value;
              }
              return child;
            });
          }
          if (sharedColumnProperties[key].fields.includes(column.field) && sharedColumnProperties) {
            column[key] = sharedColumnProperties[key].value;
          }
        });
      }
      return column;
    }), [sharedColumnProperties]);

  const handleColumnHeaderTooltips = React.useCallback(
    (columnInstance) =>
    /**
      * This function improves user experience by adding helpful tooltips to column headers in an ag-Grid setup.
      * It goes through each column, collects useful properties, and ensures they're applied consistently.
      * These tooltips make it easier to understand the displayed data and enhance the setup for better usability.
      */
      columnInstance.map((column) => {
        if (column.children && column.children.length) {
          column.children.map((child) => {
            child['headerTooltip'] = child.headerName.toUpperCase() || child.field.toUpperCase();
            if (!child.hasOwnProperty('tooltipValueGetter')) {
              child['tooltipValueGetter'] = (params) => params.value;
            }
            return child;
          });
        }
        column['headerTooltip'] = column.headerName.toUpperCase() || column.field.toUpperCase();
        if (!column.hasOwnProperty('tooltipValueGetter')) {
          column['tooltipValueGetter'] = (params) => params.value;
        }
        return column;
      }),
    [],
  );

  const handleColumnProperties = React.useCallback(() => {
    const columnInstance = columnDefinition;
    const functionsToApply = [
      handleBooked,
      handleCapitalization,
      handleChildColumnHeaders,
      handleSharedColumnProperties,
      handleColumnHeaderTooltips,
    ];
    const modifiedColumnDefinition = functionsToApply.reduce((column, func) => func(column), columnInstance);
    setColumnDefinition(modifiedColumnDefinition);
    // TODO: Create overall loader for datagrid
    setGridIsLoading(false);
  }, [
    columnDefinition,
    handleBooked,
    handleCapitalization,
    handleChildColumnHeaders,
    handleSharedColumnProperties,
    handleColumnHeaderTooltips,
  ]);

  const handleOnSelectionChanged = React.useCallback(() => {
    // Added to handle selected rows count
    const rows = mainGridRef.current.api.getSelectedNodes();
    if (onSelectionChanged) onSelectionChanged(rows);
    mainGridRef.current.api.refreshCells({ force: true }); // this line allows the cell color to go back to it's intial color setting after being deselected
  }, []);

  const handleOnMainGridReady = React.useCallback((params) => {
    if (onGridReady) onGridReady(params);
    if (gridState) handleGridStateApplication(columnDefinition, gridState, mainGridRef);
  }, [gridState, columnDefinition, onGridReady]);

  const handleOnFilterChanged = React.useCallback((params) => {
    setRowsToDisplay(params.api.rowModel.rowsToDisplay.length);
    mainGridRef.current.api.refreshCells({ force: true });
  }, []);

  const handleOnSortChanged = React.useCallback(() => {
    mainGridRef.current.api.refreshCells({ force: true });
  }, []);

  const handleOnRowDataUpdated = (params) => {
    // eslint-disable-next-line no-unused-expressions
    onRowDataUpdated && onRowDataUpdated(params);
  };

  const handleOnGridPreDestroyed = React.useCallback((params) => {
    if (gridName) {
      const requestBody = {
        grid: gridName,
        state: JSON.stringify(params.state),
      };

      SaveGridStateAPI({ requestBody });
    }
  }, [gridState]);

  React.useEffect(() => {
    let timeoutId;

    if (!gridIsLoading && gridState) {
      timeoutId = setTimeout(() => setIsMainGridReady(true), 300);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [gridIsLoading, gridState]);

  React.useEffect(() => {
    handleColumnProperties();
  }, []);

  React.useEffect(() => {
    mainGridRef.current?.api?.setGridOption('columnDefs', columnDefinition);
  }, [columns]);

  React.useEffect(() => {
    // Hides the noRowsOverlayComponent when grid or rows are being loaded
    // Prevents overlapping with BootstrapSpinner
    if (!isMainGridReady || loading || gridIsLoading) {
      if (mainGridRef.current?.api) mainGridRef.current?.api.hideOverlay();
    }
  }, [loading, isMainGridReady, gridIsLoading]);

  React.useEffect(() => {
    if (gridName) {
      GetGridStateAPI({
        queryParams: { gridName },
        onSuccess: (res) => {
          if (res.state === "") {
            setGridState({});
          } else {
            setGridState(JSON.parse(res.state));
          }
        },
      });
    }
  }, []);

  return (
    <div className={theme === 'primary' ? columnIsWithChildren ? 'ag-theme-op2mise-2' : 'ag-theme-op2mise' : 'ag-theme-op2mise-simple'} style={dataGridStyle}>
      {(!isMainGridReady || loading) && (
        // Shows grid loader for a milli-second to allow data-grid to fully render first before showing
        <div
          style={{
            width: '100%',
            height: dataGridStyle.height,
            zIndex: 100,
            borderRadius: '6px',
            background: 'var(--op2mise-color-white)',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <BootstrapSpinner />
          </div>
        </div>
      )}
      {(!gridIsLoading && gridState) && (
        <AgGridReact
          alignedGrids={[overviewGridRef]}
          columnDefs={columnDefinition}
          defaultColDef={defaultColumnProperties}
          noRowsOverlayComponent={noRowsOverlayComponent}
          onFilterChanged={handleOnFilterChanged}
          onGridReady={handleOnMainGridReady}
          onGridPreDestroyed={handleOnGridPreDestroyed}
          onRowDataUpdated={handleOnRowDataUpdated}
          onRowGroupOpened={(params) => {
            const isExpanded = params.api.rowModel.rowsToDisplay.find((obj) => obj.expanded);
            if (isExpanded) {
              setIsVerticalScroll(true);
            } else {
              setIsVerticalScroll(false);
            }
          }}
          onSelectionChanged={handleOnSelectionChanged}
          onSortChanged={handleOnSortChanged}
          pagination={pagination}
          paginationPageSize={pageSize}
          ref={mainGridRef}
          reactiveCustomComponents
          rowData={rowData}
          rowClassRules={{
            ...rowClassRules,
            ...(showAlternativeRowHighlight && {
              'ag-row-alternate': (params) => params && params.rowIndex % 2 !== 0,
            }),
          }}
          suppressScrollOnNewData
          rowSelection={enableMultipleRowSelection ? 'multiple' : 'single'}
          rowMultiSelectWithClick={enableMultipleRowSelection}
          suppressMovableColumns={suppressMovableColumns}
          suppressRowClickSelection={useCheckboxForRowSelection}
          suppressChangeDetection
          {...enableMultipleColumnSorting && { multiSortKey: 'ctrl' }}
          {...grid.main.DEFAULT}
          {...mainGridRestProps}
        />
      )}
    </div>
  );
}
